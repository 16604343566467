
import {
  defineComponent,
  ref,
  watch
} from 'vue';

import { getDashboardPlanList, DashboardPlanListResponse } from '@/services/api';
import dayjs from 'dayjs';

export default defineComponent({
  props: {
    /**
     * select_date
     * @type Array [endDate,startDate]
     */
    selectDate: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const page = ref(1);
    const data = ref<DashboardPlanListResponse>({});

    const now = dayjs().format('YYYY.MM.DD');
    const sixDaysBefore = dayjs().subtract(6, 'day').format('YYYY.MM.DD');
    watch([page, () => props.selectDate], async([pageValue, selectDate]) => {
      isLoading.value = true;
      if (selectDate.length === 0) return;
      const res = await getDashboardPlanList({
        query: {
          page: page.value,
          // eslint-disable-next-line @typescript-eslint/camelcase
          start_date: `${selectDate[1]}`,
          // eslint-disable-next-line @typescript-eslint/camelcase
          end_date: `${selectDate[0]}`
        }
      });

      data.value = res;
      isLoading.value = false;
    }, {
      immediate: true
    });

    return {
      isLoading,
      page,
      data,
      now,
      sixDaysBefore
    };
  }
});
