
import { useI18n } from 'vue-i18n';
import { ref, computed } from 'vue';
import dayjs from 'dayjs';

export default {
  /**
   * @description
   * dashboard裡某幾個 charts 圖上方的 days select 組件
   */
  props: {
    dateRangeArr: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:dateRangeArr'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const options = [
      {
        value: 0,
        label: t('selectOption.today')
      }, {
        value: 1,
        label: t('selectOption.yesterday')
      }, {
        value: 3,
        label: t('selectOption.last3days')
      }, {
        value: 7,
        label: t('selectOption.last7days')
      }
    ];
    const daysOptions = ref(options);
    const selectValue = ref(daysOptions.value[2].value);
    const dateArr: any = computed({
      get: () => props.dateRangeArr,
      set: (value) => emit('update:dateRangeArr', value)
    });

    /**
     * 依照選取的日期做出一個陣列
     * [{end_date},{start_date}]
     * start_date:xxx,
     * end_date:xxx (today)
     * yesterday = [{yesterday}, {yesterday}]
     * last 3 days = [{today}, {today - 2}]
     * 以此類推
     * @param date 日種類
     */
    const emitDateRange = (date: number) => {
      const TIME_FORMAT = 'YYYY-MM-DD';
      const today = dayjs(new Date());
      const endDate = today.format(TIME_FORMAT);
      const innerDateArr = ref([]);
      if (date === 1) {
        const yesterday = today.subtract(1, 'day').format(TIME_FORMAT);
        innerDateArr.value = [yesterday, yesterday];
      } else if (date === 0) {
        innerDateArr.value = [endDate, endDate];
      } else {
        const startDate = today.subtract(date - 1, 'day').format(TIME_FORMAT);
        innerDateArr.value = [endDate, startDate];
      }
      dateArr.value = innerDateArr.value;
    };
    const handleSelect = (val: number) => {
      emitDateRange(val);
    };
    handleSelect(selectValue.value);

    return {
      daysOptions,
      handleSelect,
      selectValue,
      dateArr
    };
  }
};
